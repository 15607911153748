<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px" size="small">
      <el-form-item label="参数名" prop="paramKey">
        <el-input v-model="dataForm.paramKey" placeholder="参数名"></el-input>
      </el-form-item>
      <el-form-item label="参数值" prop="paramValue">
        <el-input v-model="dataForm.paramValue" placeholder="参数值"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="dataForm.remark" placeholder="备注"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { configInfo, configDataFormSubmit } from "@/api/sys";
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: 0,
        paramKey: "",
        paramValue: "",
        remark: ""
      },
      dataRule: {
        paramKey: [
          { required: true, message: "参数名不能为空", trigger: "blur" }
        ],
        paramValue: [
          { required: true, message: "参数值不能为空", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    init(id) {
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(async () => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          const { data } = await configInfo(
            `/sys/config/info/${this.dataForm.id}`
          );
          if (data && data.code === 0) {
            this.dataForm.paramKey = data.config.paramKey;
            this.dataForm.paramValue = data.config.paramValue;
            this.dataForm.remark = data.config.remark;
          }
          // this.$http({
          //   url: this.$http.adornUrl(`/sys/config/info/${this.dataForm.id}`),
          //   method: "get",
          //   params: this.$http.adornParams()
          // }).then(({ data }) => {
          //   if (data && data.code === 0) {
          //     this.dataForm.paramKey = data.config.paramKey;
          //     this.dataForm.paramValue = data.config.paramValue;
          //     this.dataForm.remark = data.config.remark;
          //   }
          // });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid) {
          const params = {
            id: this.dataForm.id || undefined,
            paramKey: this.dataForm.paramKey,
            paramValue: this.dataForm.paramValue,
            remark: this.dataForm.remark
          };
          const { data } = await configDataFormSubmit(
            `/sys/config/${!this.dataForm.id ? "save" : "update"}`,
            params
          );
          if (data && data.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit("refreshDataList");
              }
            });
          } else {
            this.$message.error(data.msg);
          }

          // this.$http({
          //   url: this.$http.adornUrl(
          //     `/sys/config/${!this.dataForm.id ? "save" : "update"}`
          //   ),
          //   method: "post",
          //   data: this.$http.adornData({
          //     id: this.dataForm.id || undefined,
          //     paramKey: this.dataForm.paramKey,
          //     paramValue: this.dataForm.paramValue,
          //     remark: this.dataForm.remark
          //   })
          // }).then(({ data }) => {
          //   if (data && data.code === 0) {
          //     this.$message({
          //       message: "操作成功",
          //       type: "success",
          //       duration: 1500,
          //       onClose: () => {
          //         this.visible = false;
          //         this.$emit("refreshDataList");
          //       }
          //     });
          //   } else {
          //     this.$message.error(data.msg);
          //   }
          // });
        }
      });
    }
  }
};
</script>
