import request from '@/utils/request'

//打样动态
export function sampleDynamicStatistics () {
    return request({
        url: '/statistics/laboratoryStatistics/sampleDynamicStatistics',
        method: 'get'
    })
}

//人均查色，匹配率，人均打样数
export function searchHistoryStatistics () {
    return request({
        url: '/statistics/laboratoryStatistics/searchHistoryStatistics',
        method: 'get'
    })
}

//打样订单信息
export function sampleOrderStatistics () {
    return request({
        url: '/statistics/laboratoryStatistics/sampleOrderStatistics',
        method: 'get'
    })
}

//打样产品新增趋势
export function sampleProductAddTrendStatistics () {
    return request({
        url: '/statistics/laboratoryStatistics/sampleProductAddTrendStatistics',
        method: 'get'
    })
}

//打样趋势
export function sampleTrendStatistics () {
    return request({
        url: '/statistics/laboratoryStatistics/sampleTrendStatistics',
        method: 'get'
    })
}

//打样效率
export function prooferEfficiencyStatistics (params) {
    return request({
        url: '/statistics/laboratoryStatistics/prooferEfficiencyStatistics',
        method: 'get',
        params: params
    })
}